import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import { Select } from "shared/Select/Select";
import VenueInfoCard from "Events/Components/VenueInfoCard";
import Checkbox from "shared/@forms/Checkbox";
import Loader from "shared/Loader";
import styles from "./VenueV1Form.module.css";
import { useManageVenuesPage } from "Events/Pages/ManageVenuesPage/useManageVenuesPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { capitalizeCityName } from "shared/@utils/capitalizeCityName";
import VenueCard from "Events/Components/VenueCard";

//Area to fill out information about the venue with a map displaying the venue location
export function VenueV1Form({ venueInfo, onVenueChange, pageMode }) {
  let rulesetType = "all";
  let valueToReadFrom =
    venueInfo.event.ruleset.saved || venueInfo.event.ruleset.value;
  if (valueToReadFrom.toLowerCase().includes("major")) {
    rulesetType = "major";
  } else if (valueToReadFrom.toLowerCase().includes("local")) {
    rulesetType = "local";
  } else if (valueToReadFrom.toLowerCase().includes("los")) {
    rulesetType = "major";
  }
  const { data: statesData } = useCurrentRoute();

  const [venueParams, setVenueParams] = useState({
    ruleset: rulesetType,
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    page: 1,
    limit: 15,
  });

  const {
    data: venuesData,
    isLoading,
    venueOptionsData,
    isVenueOptionsDataLoading,
    setOptionsRuleset,
  } = useManageVenuesPage(venueParams);

  useEffect(() => {
    if (rulesetType) {
      setOptionsRuleset(rulesetType);
    }
  }, [rulesetType]);

  const [venueNames, setVenueNames] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [noVenue, setNoVenue] = useState(false);
  const [userHasSelected, setUserHasSelected] = useState(false);
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);
  const [stateNameToIdMap, setStateNameToIdMap] = useState({});

  useEffect(() => {
    if (selectedState && selectedState !== "Select") {
      const stateIdToUse = stateNameToIdMap[selectedState];
      setVenueParams({ ...venueParams, stateId: stateIdToUse });
    }
    if (selectedCity) {
      setVenueParams({
        ...venueParams,
        city: selectedCity == "All" ? "all" : selectedCity,
      });
    }
  }, [selectedState, selectedCity]);

  const prevSelectedStateRef = useRef();

  useEffect(() => {
    if (venueOptionsData && statesData) {
      const newStateIdToNameMap = statesData.reduce((acc, state) => {
        acc[state.stateId.toString()] = state.state;
        return acc;
      }, {});

      const newStateNameToIdMap = statesData.reduce((acc, state) => {
        acc[state.state] = state.stateId.toString();
        return acc;
      }, {});
      setStateNameToIdMap(newStateNameToIdMap);

      let statesAndCities = {};

      venueOptionsData.locations.forEach((location) => {
        const stateName =
          newStateIdToNameMap[location.stateId] || "Unknown State";

        if (!statesAndCities[stateName]) {
          statesAndCities[stateName] = ["Select"];
        }

        const formattedCity = location.city;

        if (!statesAndCities[stateName].includes(formattedCity)) {
          statesAndCities[stateName].push(formattedCity);
        }
      });

      Object.keys(statesAndCities).forEach((state) => {
        const cities = statesAndCities[state]
          .filter((city) => city !== "Select")
          .sort();
        statesAndCities[state] = ["Select", ...cities];
      });

      const stateOptions = ["Select", ...Object.keys(statesAndCities).sort()];
      setStates(stateOptions);
      let selectedState = "";

      selectedState =
        newStateIdToNameMap[
          venueInfo?.event?.state?.saved ||
            venueInfo?.event?.state?.value ||
            "Select"
        ];

      setSelectedState(selectedState);
    }
  }, [venueOptionsData]);

  useEffect(() => {
    if (selectedState && venueOptionsData) {
      const newStateIdToNameMap = statesData.reduce((acc, state) => {
        acc[state.stateId.toString()] = state.state;
        return acc;
      }, {});
      let statesAndCities = {};

      venueOptionsData.locations.forEach((location) => {
        const stateName =
          newStateIdToNameMap[location.stateId] || "Unknown State";

        if (!statesAndCities[stateName]) {
          statesAndCities[stateName] = [];
        }

        const formattedCity = capitalizeCityName(location.city);

        if (!statesAndCities[stateName].includes(formattedCity)) {
          statesAndCities[stateName].push(formattedCity);
        }
      });

      Object.keys(statesAndCities).forEach((state) => {
        const cities = statesAndCities[state]
          .filter((city) => city !== "All")
          .sort();
        statesAndCities[state] = ["All", ...cities];
      });

      const cityOptions =
        selectedState &&
        selectedState !== "Select" &&
        statesAndCities[selectedState]
          ? statesAndCities[selectedState]
          : ["Select"];
      setCities(cityOptions);
    }
  }, [selectedState]);

  useEffect(() => {
    const savedCompany = venueInfo.venue?.company?.saved?.company;
    const valueCompany = venueInfo.venue?.company?.value?.company;

    if (savedCompany === null) {
      //This makes it impossible for State to be initially set while company is null
      // setSelectedState("");
      // setSelectedCity("");
      setSelectedVenue(valueCompany);
    } else {
      setSelectedVenue(savedCompany || valueCompany || "");
    }
  }, [venueInfo]);

  const specificRuleset = venueInfo?.event?.ruleset?.value;

  //Filter state & city
  useEffect(() => {
    //Filter venues by specificRuleset
    const filteredVenues = venuesData?.data?.filter(
      (venue) =>
        venue.ruleset === specificRuleset &&
        (venue.companyId || venue.company || venue.roomId)
    );

    //Extract unique states
    const uniqueStateIds = [
      ...new Set(filteredVenues?.map((venue) => venue.stateId)),
    ];
    const stateOptions = uniqueStateIds
      ?.map(
        (stateId) =>
          statesData?.find((state) => state.stateId === stateId)?.state
      )
      ?.filter(Boolean)
      .sort((a, b) => a.localeCompare(b));

    // setStates([...stateOptions]);

    // Only reset city and venue if the selected state has changed
    if (
      selectedState !== prevSelectedStateRef.current &&
      selectedVenue === null
    ) {
      setSelectedCity("");
      setSelectedVenue("");
    }

    //Filter cities based on the selected state
    const stateId = statesData?.find(
      (state) => state.state === selectedState
    )?.stateId;
    let cities = filteredVenues
      ?.filter((venue) => venue.stateId === stateId)
      .map((venue) => venue.city.trim().toUpperCase())
      .map((city) => (city === "VANCOUVER WA" ? "VANCOUVER" : city))
      .map((city) =>
        city
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
      );

    const uniqueCities = Array.from(new Set(cities)).sort((a, b) =>
      a.localeCompare(b)
    );

    // setCities([...uniqueCities]);

    prevSelectedStateRef.current = selectedState;
  }, [venuesData, specificRuleset, statesData, selectedState]);

  useEffect(() => {
    // Define a method to extract venue and room names safely
    const getVenueAndRoom = (venueInfo) => {
      const company =
        venueInfo.venue?.company?.saved?.company ||
        venueInfo.venue?.company?.value?.company ||
        "";
      const room =
        venueInfo.venue?.room?.saved?.room ||
        venueInfo.venue?.room?.value?.room ||
        "No Room";
      return `${company} (${room})`;
    };

    if (
      !userHasSelected &&
      (venueInfo.venue?.company?.saved?.company ||
        venueInfo.venue?.company?.value?.company ||
        venueInfo.venue?.room?.saved?.room ||
        venueInfo.venue?.room?.value?.room)
    ) {
      const initialVenueAndRoom = getVenueAndRoom(venueInfo);
      setSelectedVenue(initialVenueAndRoom);
    }

    let roomList = [];
    if (selectedState && selectedCity) {
      const stateId = statesData.find(
        (state) => state.state.toLowerCase() === selectedState.toLowerCase()
      )?.stateId;

      const filteredVenues = venuesData?.data?.filter((venue) => {
        const matchesState = venue.stateId === stateId;
        const matchesCity =
          selectedCity.toLowerCase() === "all" ||
          venue.city.trim().toLowerCase() === selectedCity.trim().toLowerCase();
        const matchesRuleset = venue.ruleset === specificRuleset;

        return matchesState && matchesCity && matchesRuleset;
      });

      // Populate roomList with venues that match the filter

      filteredVenues?.forEach((venue) => {
        if (venue.rooms.length > 0) {
          venue.rooms.forEach((room) => {
            const roomIdentifier = `${venue.company} ${
              room.room ? "(" + room.room + ")" : ""
            }`;
            if (!roomList.includes(roomIdentifier)) {
              roomList.push(roomIdentifier);
            }
          });
        } else {
          const roomIdentifier = `${venue.company}`;
          if (!roomList.includes(roomIdentifier)) {
            roomList.push(roomIdentifier);
          }
        }
      });

      if (
        !userHasSelected &&
        !filteredVenues?.find(
          (venue) => getVenueAndRoom({ venue }) === selectedVenue
        )
      ) {
        setSelectedVenue("Select");
      }
    }

    setVenueNames(roomList);
  }, [
    venueInfo,
    selectedState,
    selectedCity,
    venuesData,
    specificRuleset,
    statesData,
    userHasSelected,
  ]);

  const handleVenueChange = (selectedValue) => {
    // const [selectedCompany, selectedRoomWithParens] = selectedValue.split(" (");
    // const selectedRoom = selectedRoomWithParens.slice(0, -1);

    const venueData = venuesData?.data?.find(
      (venue) => venue.company === selectedValue.trim()
    );

    const payload = {
      venue: {
        company: {
          saved: {
            companyId: venueData.companyId,
            company: venueData.company,
            street1: venueData.street1,
            street2: venueData.street2,
            city: venueData.city,
            stateId: venueData.stateId,
            zip: venueData.zip,
            url: venueData.url,
            phone: venueData.phone,
          },
        },
        room:
          venueData.rooms.length > 0
            ? {
                saved: {
                  roomId: venueData.rooms[0].roomId,
                  room: venueData.rooms[0].room,
                  maxCapacity: venueData.rooms[0].maxCapacity,
                },
              }
            : {},
      },
    };

    setSelectedCity(venueData.city);
    onVenueChange(payload);
    setSelectedVenue(selectedValue);
    setUserHasSelected(true);
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity("");
    setSelectedVenue("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value == "All" ? "all" : value);
    setSelectedVenue("");
  };

  //Update no venue
  const handleNoVenue = async () => {
    setIsLoadingVenue(true);

    const newValue = !noVenue;
    setNoVenue(newValue);

    if (newValue) {
      // setIsLoadingVenue(true);
      const hasSavedVenue =
        venueInfo.venue &&
        venueInfo.venue.company.saved &&
        (venueInfo.venue.company.saved.companyId ||
          venueInfo.venue.company.saved.company);

      if (!hasSavedVenue) {
        setIsLoadingVenue(false);
        return;
      }

      const resetVenue = {
        venue: {
          company: {
            saved: {
              companyId: null,
              company: null,
              street1: null,
              street2: null,
              city: null,
              stateId: null,
              zip: null,
              url: null,
              phone: null,
            },
          },
          room: {
            saved: {
              roomId: null,
              room: null,
              maxCapacity: null,
            },
          },
        },
      };

      try {
        await onVenueChange(resetVenue);
      } catch (error) {
        console.error("Error resetting venue:", error);
      }
    } else {
      setSelectedState("");
      setSelectedCity("");
      setSelectedVenue("");
    }

    setIsLoadingVenue(false);
  };

  const renderVenueCard = () => {
    const companyPermission = venueInfo?.venue?.company?.permission;

    const permission = pageMode === "view" ? "read" : companyPermission;

    if (pageMode === "edit") {
      return (
        <Card
          children={
            <section>
              <div className={styles.title}>VENUE</div>
              <hr className={styles.hr} />

              {isLoading || isVenueOptionsDataLoading ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : (
                <>
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>No Event Venue</span>
                      <span className={styles.checkbox}>
                        <Checkbox onChange={handleNoVenue} checked={noVenue} />
                      </span>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label}>Filter</span>
                      <span className={styles.filterDropdown}>
                        <Select
                          placeholder={selectedState || "State"}
                          value={selectedState}
                          options={states}
                          onChange={(value) => handleStateChange(value)}
                          disabled={noVenue}
                          className={noVenue ? styles.disabled : ""}
                        />
                      </span>
                      <span className={styles.filterDropdown}>
                        <Select
                          placeholder={
                            selectedCity == "all"
                              ? "All"
                              : selectedCity || "City"
                          }
                          value={selectedCity}
                          options={cities}
                          onChange={(value) => {
                            handleCityChange(value);
                          }}
                          disabled={
                            selectedState === "Select" ||
                            !selectedState ||
                            noVenue
                          }
                          className={
                            !selectedState || selectedState === "Select"
                              ? styles.disabled
                              : ""
                          }
                        />
                      </span>
                    </div>
                  </div>

                  {isLoadingVenue && !selectedVenue ? (
                    <div className={styles.loader}>
                      <Loader />
                    </div>
                  ) : (
                    <div className={styles.row}>
                      <div className={styles.leftCol}>
                        <span className={styles.label}>Venue *</span>
                        <span className={styles.venueDropdown}>
                          <Select
                            placeholder={selectedVenue || "Select"}
                            value={selectedVenue}
                            options={venueNames}
                            onChange={(value) => {
                              handleVenueChange(value);
                            }}
                            disabled={
                              !selectedState || !selectedCity || noVenue
                            }
                            className={
                              !selectedState || !selectedCity || noVenue
                                ? styles.disabled
                                : ""
                            }
                          />
                        </span>
                      </div>
                    </div>
                  )}

                  <div className={styles.row}>
                    <div className={styles.leftCol}>
                      <span className={styles.label} />

                      {venueInfo &&
                      !noVenue &&
                      selectedVenue &&
                      selectedVenue !== "Select" ? (
                        <div className={styles.infoCard}>
                          <VenueInfoCard venueInfo={venueInfo.venue} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
            </section>
          }
        />
      );
    }

    if (pageMode === "view") {
      return <VenueCard venueInfo={venueInfo} />;
    }

    if (permission === "hidden") {
      return null;
    }
  };

  return <>{renderVenueCard()}</>;
}

VenueV1Form.propTypes = {
  //Value of state select
  state: PropTypes.string,
  //sets the state select
  setState: PropTypes.func,
  //value of city select
  city: PropTypes.string,
  //sets the city select
  setCity: PropTypes.func,
  //value of venue select
  venue: PropTypes.string,
  //sets the venue select
  setVenue: PropTypes.func,
  //Boolean that decides if there is no event venue
  noVenue: PropTypes.bool,
  //sets noVenue to true/false
  setNoVenue: PropTypes.func,
  //Function called when add new venue is clicked
  addNewVenue: PropTypes.func,
};
