import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { createContainer } from "unstated-next";

export function useRefundTicket() {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();

  const refundTicketMutation = useMutation(
    async ({ iboNumber, orderId, year, amountPaid, ccId, ticketId, addFee, amountRefunding }) => {
      const response = await makeAuthedRequest(
        `v1/events/order/ticket/refund`,
        {
          method: "PUT",
          body: JSON.stringify({ iboNumber, orderId, year, amountPaid, ccId, ticketId, addFee, amountRefunding }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 204 || response.status === 200) {
        return { message: amountRefunding === 0 ? "Ticket cancellation successful!" : "Ticket refund successful!", status: response.status };
      } else if (response.status === 409) {
        return {
          message: "Error refunding ticket.",
          status: response.status,
        };
      } else {
        return {
          message: "An error occured. Please try again later",
          status: response.status,
        };
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("orders");
      },
    }
  );

  return refundTicketMutation;
}

export const RefundTicketContainer = createContainer(useRefundTicket);
