import styles from "./EditVenueModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { EditSelect } from "../EditSelect/EditSelect";
import Select from "../Select";
import { Modal } from "../../../shared/Modal";
import { RadioButtonList } from "../RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

/**
 *  TODO:
 *   - Replace Inputs with InputItem when merged with develop
 *   - Fix inevitable styling errors when doing so
 *   - add placeholder text when InputItem merged
 *   - change modal import to shared modal when Events/Modal is switched to shared folder
 *   - when InputItem is merged, change value to venue prop data
 */

export function EditVenueModal({
  isOpen,
  onCollapse,
  onClick,
  onDelete,
  venue,
}) {
  const venueToUse = venue ?? {};

  const { data: statesData, isLoading: isLoadingStates } = useCurrentRoute();

  const [newName, setNewName] = useState(venueToUse.name ?? "");
  const [newAddress1, setNewAddress1] = useState(venueToUse.street ?? "");
  const [newAddress2, setNewAddress2] = useState(venueToUse.street2 ?? "");
  const [newCity, setNewCity] = useState(venueToUse.city ?? "");
  const [newState, setNewState] = useState(venueToUse.state ?? "");
  const [newStateId, setNewStateId] = useState(venueToUse.state ?? "");
  const [newZip, setNewZip] = useState(venueToUse.zip ?? "");
  const [newVenueURL, setNewVenueURL] = useState(venueToUse.venueURL ?? "");
  const [venueList, setVenueList] = useState(venueToUse.rooms ?? []);
  const [contactList, setContactList] = useState(venueToUse.contacts ?? []);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (statesData) {
      const stateNames = statesData.map((state) => state.state);
      setStates(stateNames.filter((state) => state !== "N/A"));
    }
  }, [statesData]);

  useEffect(() => {
    // Update state variables when the venue prop changes
    setNewName(venueToUse.name ?? "");
    setNewAddress1(venueToUse.street ?? "");
    setNewAddress2(venueToUse.street2 ?? "");
    setNewCity(venueToUse.city ?? "");
    setNewStateId(venueToUse.state ?? "");
    // setNewState(statesData.find((item) => item.stateId == venueToUse?.state?.toString()) || "");
    setNewZip(venueToUse.zip ?? "");
    setNewVenueURL(venueToUse.venueURL ?? "");
    setVenueList(venueToUse.rooms ?? []);
    setContactList(venueToUse.contacts ?? []);
  }, [venue]);

  useEffect(() => {
    if (venueToUse.state && statesData) {
      const selectedState = statesData.find(
        (item) => item.stateId.toString() === venueToUse.state.toString()
      );
      setNewState(selectedState ? selectedState.state : "");
    }
  }, [venue, statesData]);

  const newVenue = {
    newName,
    newAddress1,
    newAddress2,
    newCity,
    newState,
    newZip,
    newVenueURL,
    venueList,
    contactList,
  };

  const onVenueRemove = (index) => {
    venueList.length > 1 &&
      setVenueList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    venueList.length === 1 && setVenueList(() => [{ room: "", capacity: "" }]);
  };

  const onVenueAdd = () => {
    setVenueList((arr) => [...arr, { name: "", capacity: "" }]);
  };

  const onContactRemove = (index) => {
    contactList.length > 1 &&
      setContactList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    contactList.length === 1 &&
      setContactList(() => [
        { contactName: "", phoneNumber: "", email: "", type: "" },
      ]);
  };

  const handleStateChange = (value) => {
    setNewState(value.split(" - ")[1]);//Update to have all cases match when api hookup is done
    const stateWithId = statesData.find(
      (item) => item.state.toString() === value.split(" - ")[1]
    );
    setNewStateId(stateWithId.state);
  };

  const [venuePhone, setVenuePhone] = useState("");
  const [venueFax, setVenueFax] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [venueURL, setVenueURL] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("U.S.");
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };
  // const handleFormSubmittal = () => {
  //   const formData = {
  //     venueName: newName,
  //     address1: newAddress1,
  //     address2: newAddress2,
  //     city: newCity,
  //     state: newState,
  //     zip: newZip,
  //     venueURL: newVenueURL,
  //     venueList: venueList,
  //     contactList: contactList,
  //   };
  //   onClick(formData);
  // };

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Province*";
      case "JAMAICA":
        return "Parish*";
      default:
        return "State*";
    }
  };
  const getPostalLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Postal Code*";
      case "JAMAICA":
        return "Postal Code*";
      default:
        return "ZIP Code*";
    }
  };

  const handleStateSelection = (value) => {
    setNewState(value);
  }
  
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (statesData) {
      const matchingCountryIds = Object.keys(countryIdName).filter(
        (key) => countryIdName[key] === selectedCountry
      );

      if (matchingCountryIds.length > 0) {
        const filteredStates = statesData
          .filter((state) =>
            matchingCountryIds.includes(state.countryId.toString())
          )
          .filter((state) => state.state !== "N/A")
          .map((state) => `${state.abbreviation} - ${state.state}`);

        //setStateOptions if they have changed,
        setStateOptions((prevStateOptions) => {
          const newOptions = filteredStates;
          return prevStateOptions.join() !== newOptions.join()
            ? newOptions
            : prevStateOptions;
        });
      } else {
        setStateOptions([]);
      }
    }
  }, [statesData, selectedCountry]);

  const renderCountryField = () => {
    // Sequence of countries
    if (statesData) {
      const countrySequence = ["U.S.", "CDN", "ANZ", "JAMAICA"];

      const existingCountry = new Set(
        statesData.map((state) => state.countryId)
      );

      // Filter valid country options
      const countryOptions = countrySequence.filter((countryName) =>
        Object.entries(countryIdName).some(
          ([id, name]) =>
            name === countryName && existingCountry.has(parseInt(id))
        )
      );

      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelect}
            options={countryOptions}
            defaultValue={selectedCountry}
            groupName="venueCountry"
          />
        </span>
      );
    }
  };
  const renderStateSelection = () => {
    // const stateValue =
    //   eventData?.state?.value === 0 || eventData?.state === 0
    //     ? "N/A"
    //     : eventData?.state?.value || eventData?.state?.saved;

    // const hasStateError = validationErrors["state"];

    // Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = statesData?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    return (
      <div className={styles.stateContainer}>
        {/* <div className={styles.stateLabel}>
          {getLabelForCountry(selectedCountry) || "State*"}
        </div>
        <div className={styles.stateDropdown}> */}
        {/* <Select
            placeholder={newState ? newState : "Select"}
            value={newState}
            options={stateOptions}
            onChange={handleStateSelection}
          /> */}
        <EditSelect
          label={getLabelForCountry(selectedCountry) || "State*"}
          options={stateOptions}
          placeholder={newState ? newState : "Select"}
          onChange={(value) => handleStateChange(value)}
          className={styles.selectDropdown}
        />
        {/* </div> */}
      </div>
    );
  };
  return (
    <Modal
      title="EDIT VENUE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Name*"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 1*"
                  placeholder=""
                  value={newAddress1}
                  onChange={(e) => {
                    setNewAddress1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 2*"
                  placeholder=""
                  value={newAddress2}
                  onChange={(e) => {
                    setNewAddress2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country* </span>
                {renderCountryField()}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City*"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>{renderStateSelection()}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label={getPostalLabelForCountry(selectedCountry) || "Zip*"}
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Phone"
                  placeholder=""
                  value={venuePhone}
                  onChange={(e) => {
                    setVenuePhone(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Fax"
                  placeholder=""
                  value={venueFax}
                  onChange={(e) => {
                    setVenueFax(e.target.value);
                  }}
                />
              </div>
              {venue &&
                venue.ruleset &&
                !(venue.ruleset.toLowerCase().includes("v1.0")) && (
                  <div className={styles.inputRow}>
                    <InputItem
                      label="Venue Email"
                      placeholder=""
                      value={venueEmail}
                      onChange={(e) => {
                        setVenueEmail(e.target.value);
                      }}
                    />
                  </div>
                )}
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue URL"
                  placeholder=""
                  value={venueURL}
                  onChange={(e) => {
                    setVenueURL(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              {venueList?.map((room, index) => (
                <div className={styles.inputRow} key={index}>
                  <InputItem
                    label="Room"
                    value={room.room}
                    placeholder=""
                    onChange={(e) => {
                      room.room = e.target.value;
                      setVenueList([...venueList]);
                      console.log(e.target.value);
                    }}
                  />
                  <InputItem
                    label="Capacity"
                    value={room.capacity}
                    placeholder=""
                    onChange={(e) => {
                      room.capacity = e.target.value;
                      setVenueList([...venueList]);
                      console.log(e.target.value);
                    }}
                  />
                  <div className={styles.removeBtn}>
                    <Button
                      className={styles.remove}
                      children="Remove"
                      onClick={() => onVenueRemove(index)}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.button}>
                <AddButton
                  color="#9AB5BB"
                  description="Add Another Room"
                  onClick={onVenueAdd}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <>
                    <Button
                      className={styles.delete}
                      children="ARCHIVE"
                      onClick={onDelete}
                    />
                    <Button
                      className={styles.update}
                      children="UPDATE"
                      onClick={
                        () =>
                          onClick({
                            newName,
                            newAddress1,
                            newAddress2,
                            newCity,
                            newStateId,
                            newZip,
                            newVenueURL,
                            venueList,
                            contactList,
                          })
                        // handleFormSubmittal()
                      }
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

EditVenueModal.propTypes = {
  // Getter to determine if modal is displayed
  isOpen: PropTypes.bool,

  // Callback function for when the modal is closed
  onCollapse: PropTypes.func,

  // Function called when the save venue btn is clicked
  onClick: PropTypes.func,

  // Function called when the delete btn is clicked
  onDelete: PropTypes.func,

  // Object with the venues info
  venue: PropTypes.shape({
    name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    rooms: PropTypes.array,
    // rooms: PropTypes.arrayOf(PropTypes.string),
    venueURL: PropTypes.string,
    contacts: PropTypes.array,
  }),
};
