import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./VenueList.module.css";
import classNames from "classnames";
import VenueRow from "Events/Components/VenueRow";
import TableSortItem from "shared/TableSortItem";
import EditVenueModal from "Events/Components/EditVenueModal";
import DeleteModal from "Events/Components/DeleteModal";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import Loader from "shared/Loader";
import ComparisonModal from "Events/Components/ComparisonModal";

/**
 * Displays the information of all past venues with a header and pagination on bottom.
 * Columns can be sorted in alphabetical and reverse alphabetical order by clicking the array next to the column label
 */

export function VenueList({ venueList, onEdit, sort, setSort }) {
  const { data: states, isLoading: isLoadingStates } = useCurrentRoute();
  const [stateIdToNameMap, setStateIdToNameMap] = useState({});

  // Convert state data to a map for easy lookup
  useEffect(() => {
    if (states && !isLoadingStates) {
      const map = states.reduce((acc, state) => {
        acc[state.stateId] = state.state;
        return acc;
      }, {});
      setStateIdToNameMap(map);
    }
  }, [states, isLoadingStates]);

  const [showEditVenue, setShowEditVenue] = useState(false);
  const [showDeleteVenue, setShowDeleteVenue] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [originalVenues, setOriginalVenues] = useState([]);
  const [venues, setVenues] = useState([]);
  const [suggestedAddress, setSuggestedAddress] = useState({
    line1: "123 Suggested St",
    city: "Suggested City",
    state: "CA",
    postalCode: "98765",
    country: "US",
  });
  const [formData, setFormData] = useState({
    contactList: [{ contactName: "", phoneNumber: "", email: "", type: "" }],
    newAddress1: "",
    newAddress2: "",
    newCity: "",
    newName: "",
    newStateId: "",
    newVenueURL: "",
    newZip: "",
    venueList: [{ room: "", capacity: "" }],
  });

  const getStateNameById = (stateId) => {
    return stateIdToNameMap[stateId] || "Unknown State";
  };

  const editVenue = (updatedVenue) => {
    setFormData(updatedVenue);
    setShowEditVenue(false);
    setComparisonModalOpen(true);
    // Optionally, update the venue list state here
  };

  const handleUseSuggested = () => {
    const newFormData = {
      ...formData,
      newAddress1: suggestedAddress.line1,
      newAddress2: suggestedAddress.line2 || "",
      newCity: suggestedAddress.city,
      newStateId: suggestedAddress.state,
      newZip: suggestedAddress.postalCode,
      country: suggestedAddress.country,
    };
    setFormData(newFormData);
    submitVenue(newFormData);
  };

  const submitVenue = (formDataToSubmit) => {
    setComparisonModalOpen(false);
    console.log("Venue updated:", formDataToSubmit);
    setShowEditVenue(false);
  };

  const handleEditVenue = (venue) => {
    console.log("Editing venue:", venue);
    setSelectedVenue(venue);
    setShowEditVenue(true);
  };

  const handleDeleteVenue = (venueId) => {
    console.log("Deleting venue with ID:", venueId);
    setShowDeleteVenue(false);
    // Optionally, update the venue list state here
  };

  const onDeleteClicked = () => {
    setShowDeleteVenue(true);
    setShowEditVenue(false);
  };

  const handleSortChange = (property) => {
    // Toggle between "asc" and "desc" only, resetting others
    const newSortOrder = sort === `${property}-asc` ? "desc" : "asc";

    // Update sortOrder state to reflect the change
    setSort(`${property}-${newSortOrder}`);
  };

  // Compute the sorted venue list based on the sort prop
  const sortedVenueList = (() => {
    if (!sort) return venueList;

    const [property, order] = sort.split("-");
    const isAsc = order === "asc";

    return [...venueList].sort((a, b) => {
      let valA = (a[property] || "").toString();
      let valB = (b[property] || "").toString();

      if (property === "state") {
        valA = stateIdToNameMap[a.state] || "Unknown State";
        valB = stateIdToNameMap[b.state] || "Unknown State";
      }

      return isAsc ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });
  })();

  return (
    <div className={styles.container}>
      {/* {isLoadingStates && <Loader />} */}

      <div className={styles.sortGrid}>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("ruleset") ? sort.split("-")[1] : ""}
            title="Ruleset"
            onChange={() => handleSortChange("ruleset")}
            isBlank={!sort || !sort.startsWith("ruleset")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("name") ? sort.split("-")[1] : ""}
            title="Venue Name"
            onChange={() => handleSortChange("name")}
            isBlank={!sort || !sort.startsWith("name")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("city") ? sort.split("-")[1] : ""}
            title="City"
            onChange={() => handleSortChange("city")}
            isBlank={!sort || !sort.startsWith("city")}
          />
        </div>
        <div className={`${styles.flexItem} `}>
          <TableSortItem
            value={sort && sort.startsWith("state") ? sort.split("-")[1] : ""}
            title="State"
            onChange={() => handleSortChange("state")}
            isBlank={!sort || !sort.startsWith("state")}
          />
        </div>
      </div>

      <div className={styles.venueList}>
        {sortedVenueList && sortedVenueList.length > 0 ? (
          <>
            {sortedVenueList.map((row, index) => (
              <div
                className={classNames(
                  index % 2 === 1
                    ? styles.slightlyDarkerBackground
                    : styles.whiteBG
                )}
                key={index}
              >
                <VenueRow
                  Venue={{ ...row, state: getStateNameById(row.state) }}
                  onClick={() => handleEditVenue(row)}
                />
              </div>
            ))}
          </>
        ) : (
          <p className={styles.noVenueText}>No Venue Found</p>
        )}
      </div>

      <EditVenueModal
        isOpen={showEditVenue}
        onCollapse={() => setShowEditVenue(false)}
        onClick={(updatedVenue) => {
          editVenue(updatedVenue);
          // onEdit();
        }}
        onDelete={() => onDeleteClicked()}
        venue={selectedVenue}
      />

      <DeleteModal
        header="DELETE"
        btnName="DELETE"
        description="Confirm that you would like to delete [Venue Name]. This action cannot be undone."
        isOpen={showDeleteVenue}
        onCollapse={() => setShowDeleteVenue(false)}
        onClick={handleDeleteVenue}
      />

      <ComparisonModal
        suggestedAddress={suggestedAddress}
        address={{
          line1: formData.newAddress1,
          line2: formData.newAddress2,
          city: formData.newCity,
          state: formData.newStateId,
          postalCode: formData.newZip,
          country: "United States", //country: getCountry(formData),
        }}
        handleUseSuggested={() => handleUseSuggested()}
        handleUseOriginal={() => submitVenue(formData)}
        handleEditClick={() => {
          setComparisonModalOpen(false);
          setShowEditVenue(true);
        }}
        onClose={() => setComparisonModalOpen(false)}
        isOpen={comparisonModalOpen}
        title={"Confirm Address"}
        loading={false}
      />
    </div>
  );
}

VenueList.propTypes = {
  venueList: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
};
