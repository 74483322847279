import React, { useState, useEffect, useRef } from "react";
import Button from "shared/Button";
import styles from "./ComparisonModal.module.css";
import PropTypes from "prop-types";
import AddressCard from "../AddressCard";
import ReactModal from "react-modal";
import { IoIosCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons";
import classNames from "classnames";

export function ComparisonModal({
  suggestedAddress,
  address,
  handleUseSuggested,
  handleUseOriginal,
  handleEditClick,
  onClose,
  isOpen,
  title,
  loading,
  isAdd,
}) {
  const [suggestedData, setSuggestedData] = useState({});
  const [addressData, setAddressData] = useState({});

  useEffect(() => {
    if (isOpen) {
      if (address) {
        setAddressData({
          country: address.country,
          recipient: address.recipient,
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          isVerified: address.isVerified,
        });
      } else {
        setAddressData({
          recipient: "",
          country: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          postalCode: "",
          isVerified: 0,
        });
      }
    }
  }, [address, isOpen, isAdd]);

  useEffect(() => {
    if (suggestedAddress) {
      setSuggestedData({
        line1: suggestedAddress.line1,
        line2: suggestedAddress.line2,
        city: suggestedAddress.city,
        state: suggestedAddress.state,
        country: suggestedAddress.country,
        postalCode: suggestedAddress.postalCode,
        latitude: suggestedAddress.latitude,
        longitude: suggestedAddress.longitude,
        isVerified: 1,

        suggestions: suggestedAddress.suggestions,
      });
    }
  }, [suggestedAddress]);

  const resetForm = () => {
    setAddressData({
      recipient: "",
      country: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      isVerified: 0,
    });
    setSuggestedData({});
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  return (
    <ReactModal
      contentLabel={title}
      isOpen={isOpen}
      className={classNames(styles.modalModal, styles.customModalClass)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.body}>
        <div className={styles.suggestedAddressContainer}>
          <AddressCard
            original={false}
            address={suggestedData}
            title={"Suggested Address"}
            onClickUse={handleUseSuggested}
          />
        </div>
        <div className={styles.userEnteredAddressContainer}>
          <AddressCard
            original={true}
            address={addressData}
            title={"Original Address"}
            onClickUse={handleUseOriginal}
            onClickEdit={handleEditClick}
          />
        </div>
      </div>
    </ReactModal>
  );
}

ComparisonModal.propTypes = {
  suggestedAddress: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  handleUseSuggested: PropTypes.func.isRequired,
  handleUseOriginal: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
