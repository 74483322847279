import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./ViewMajorV2EventManagePage.module.css";
import Header from "shared/Header";
import { useHistory } from "react-router-dom";
import { useViewEvents } from "./useViewEvents";
import Loader from "shared/Loader";
import EventTypeV2Card from "Events/V2Components/EventTypeV2Card";
import EventV2InfoForm from "Events/V2Components/EventV2InfoForm";
import PublishingV2InfoForm from "Events/V2Components/PublishingV2InfoForm";
// import DateV2Form from "Events/V2Components/DateV2Form";
import NotesV2Form from "Events/V2Components/NotesV2Form";
import TicketingV2Form from "Events/V2Components/TicketingV2Form";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import VenueV2Form from "Events/V2Components/VenueV2Form";
import Button from "shared/Button";
import VirtualCard from "Events/Components/VirtualCard";
import SessionsInfoCard from "Events/Components/SessionsInfoCard";
import ADACard from "Events/Components/ADACard";
import CircumstanceCard from "Events/Components/CircumstanceCard";

export function ViewMajorV2EventManagePage() {
  let history = useHistory();
  const { eventId } = useParams();

  const {
    data: viewEventData,
    isError,
    isLoading,
    refetch,
  } = useViewEvents(eventId);
  const [event, setEvent] = useState(null);
  const { data: states } = useCurrentRoute();
  const getStateNameById = (id) => {
    if (id === "0") return "";

    const state = states?.find(
      (state) => state?.stateId?.toString() === id?.toString()
    );

    if (state) {
      return `${state.abbreviation} - ${state.state}`;
    } else {
      return null;
    }
  };
  const [eventInfo, setEventInfo] = useState({
    event: {
      name: "",
      ruleset: "",
      location: "",
      jobCode: "",
      dates: {},
      country: "",
      city: "",
      state: "",
      notes: "",
    },
    marketing: {
      title: "",
      description: "",
      tentative: "",
      visibleCalendar: "",
      notes: "",
    },
    analytics: {
      sales: {
        soldOut: "",
        capacity: {
          purchase: 0,
          platinum: 0,
        },
      },
      countingTickets: "",
    },
    venue: {},
    tickets: [],
    //TODO: Only temp, check again..
    virtual: {},
    sessions: [],
    entries: [],
    circumstances: "",
  });

  useEffect(() => {
    refetch();
  }, [eventId, refetch]);

  useEffect(() => {
    if (viewEventData && !isLoading) {
      const companyInfo = {
        ...viewEventData.venue.company.saved,
        ...Object.fromEntries(
          Object.entries(viewEventData.venue.company.value).filter(
            ([_, v]) => v != null
          )
        ),
      };

      const roomInfo = {
        ...viewEventData.venue.room.saved,
        ...Object.fromEntries(
          Object.entries(viewEventData.venue.room.value).filter(
            ([_, v]) => v != null
          )
        ),
      };
      setEventInfo({
        event: {
          name: viewEventData.event?.name || "",
          ruleset: viewEventData.event?.ruleset || "",
          location: viewEventData.event?.location || "",
          dates: viewEventData?.event?.dates || "",
          country: viewEventData?.event?.country || "",
          city:
            viewEventData?.event?.city?.value ||
            viewEventData?.event?.city?.saved ||
            "",
          state:
            viewEventData?.event?.state || viewEventData?.event?.state || "",
          notes: viewEventData?.event?.notes || "",
        },

        //  marketing
        marketing: {
          title:
            viewEventData?.marketing?.title?.value ||
            viewEventData?.marketing?.title?.saved ||
            "",
          description:
            viewEventData.marketing?.description?.value ||
            viewEventData.marketing?.description?.saved ||
            "",
          tentative:
            viewEventData.marketing?.tentative?.value ||
            viewEventData.marketing?.tentative?.saved ||
            "",
          visibleCalendar:
            viewEventData.marketing?.visibleCalendar?.value ||
            viewEventData.marketing?.visibleCalendar?.saved ||
            "",
          notes: viewEventData?.marketing?.notes || "",
        },
        analytics: {
          countingTickets: viewEventData.analytics?.countingTickets || "",
          sales: {
            soldOut: viewEventData.analytics?.sales?.soldOut || "",
            capacity: {
              purchase: viewEventData.analytics?.sales?.capacity?.purchase || 0,
              platinum: viewEventData.analytics?.sales?.capacity?.platinum || 0,
            },
          },
        },
        venue: {
          company: viewEventData?.venue?.company || {},
          room: viewEventData?.venue?.room || {},
        },

        tickets: viewEventData.tickets?.value || [],

        //TODO: Need to add data for (virtual, sessions, entries, speakers, hosts etc.)
      });
    }
  }, [viewEventData, isLoading]);

  //Redirect
  const handleManageInvitees = (sessions) => {
    history.push("/events/manage-invitees");
  };

  const handleEditEvent = () => {
    const ruleset = viewEventData.event?.ruleset.value;

    if (ruleset === "Major v1.0") {
      history.push(`/events/edit/event/majorv1/${eventId}`);
    } else if (ruleset === "Local v1.0") {
      history.push(`/events/edit/event/localv1/${eventId}`);
    } else if (ruleset === "LOS v1.0") {
      history.push(`/events/edit/event/losv1/${eventId}`);
    } else {
      history.push("/");
    }
  };

  if (isLoading && eventInfo) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading View Major V2.0</div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.pageHeader}>
            <Header
              title={eventInfo?.marketing?.title}
              onClick={() => history.goBack()}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.editBtn}
              children={"EDIT"}
              onClick={handleEditEvent}
            />
          </div>
        </div>

        <div className={styles.pageContent}>
          {eventInfo && (
            <div className={styles.cardContainer}>
              <EventTypeV2Card eventInfo={eventInfo.event} />
            </div>
          )}

          <div className={styles.cardContainer}>
            {eventInfo && (
              <EventV2InfoForm marketingInfo={eventInfo} pageMode="view" />
            )}
          </div>
          <div className={styles.cardContainer}>
            {eventInfo && (
              <PublishingV2InfoForm
                publishingInfo={eventInfo}
                pageMode="view"
              />
            )}
          </div>

          {/* <div className={styles.cardContainer}>
            {eventInfo?.event?.dates && (
              <DateV2Form
                dateInfo={eventInfo?.event}
                location={eventInfo?.event?.location?.value}
                ruleset={eventInfo?.event?.ruleset?.value}
                eventName={""}
                pageMode="view"
              />
            )}
          </div> */}

          {/* {eventInfo?.event?.location?.value === "onsite" && (
            <div className={styles.cardContainer}>
              {eventInfo?.venue && (
                <VenueV2Form venueInfo={eventInfo} pageMode="view" />
              )}
            </div>
          )} */}

          {eventInfo?.event?.location?.value === "onsite" ? (
            <div className={styles.cardContainer}>
              <VenueV2Form venueInfo={eventInfo} pageMode="view" />
            </div>
          ) : (
            <div className={styles.cardContainer}>
              <VirtualCard
                virtualInfo={{ type: "Event Name", link: "www.livestream.com" }}
              />
            </div>
          )}

          {/* TEMPORARY */}
          <div className={styles.cardContainer}>
            {eventInfo && (
              <SessionsInfoCard
                sessions={[
                  {
                    visibility: "Public",
                    name: "Livestream",
                    description: "Description of the event",
                    speaker: "John Doe",
                    location: "Name of the Room",
                    capacity: "150",
                    startDate: "9/20/2023",
                  },
                ]}
                onManageInvitees={() => {}}
              />
            )}
          </div>

          <div className={styles.cardContainer}>
            {eventInfo && (
              <TicketingV2Form
                ticketGroups={[
                  { name: "Ticket Group Name", tickets: eventInfo?.tickets },
                ]}
                setTicketGroups={() => {}}
                eventName={eventInfo?.event?.name?.value}
              />
            )}
          </div>

          {/* TEMPORARY */}
          <div className={styles.cardContainer}>
            {eventInfo && (
              <ADACard
                entries={[
                  "Do you require accessibility assistance?",
                  "Do you require hearing / auditory assistance?",
                ]}
              />
            )}
          </div>

          {/* TEMPORARY */}
          <div className={styles.cardContainer}>
            {eventInfo && (
              <CircumstanceCard
                circumstances={"Note for security special circumstances."}
              />
            )}
          </div>

          <div className={styles.cardContainer}>
            {eventInfo && <NotesV2Form notesData={eventInfo} pageMode="view" />}
          </div>
        </div>
      </div>
    </>
  );
}
