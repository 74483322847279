import { useQuery } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { useState, useEffect } from "react";

// Moved from the first file
export function useVenueOptionsGet(ruleset = "all") {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, status, isLoading, refetch } = useQuery(
    ["venueOptions", ruleset], // Use ruleset as the query key
    () =>
      makeAuthedRequest(`v1/events/venues/options/${ruleset}`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      staleTime: 0,
    }
  );

  return {
    data,
    status,
    refetch,
    isLoading,
  };
}

export function useManageVenuesPage(params) {
  const { makeAuthedRequest } = Auth.useContainer();
  const { ruleset, stateId, city, search, sort, page, limit } = params; // Destructure params object

  const [optionsRuleset, setOptionsRuleset] = useState("all");

  const {
    data: venueOptionsData,
    status: venueOptionsStatus,
    refetch: refetchVenueOptionsData,
    isLoading: isVenueOptionsDataLoading,
  } = useVenueOptionsGet(optionsRuleset);

  useEffect(() => {
    refetchVenueOptionsData();
  }, [optionsRuleset]);

  const fetchVenues = async () => {
    const response = await makeAuthedRequest(
      `v1/events/venues/ruleset/${ruleset}/stateId/${stateId}/city/${city}/search/${search}/sort/${sort}/page/${page}/limit/${limit}`,
      {
        method: "GET",
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      const body = await response.json();
      throw new Error(body.message);
    }
  };

  const { data, status, isLoading, refetch } = useQuery(
    ["venues", params], // Use params as the query key
    fetchVenues,
    {
      onError: (error) => {
        console.log("Error fetching venues:", error.message.message);
      },
    }
  );

  return {
    data,
    status,
    isLoading,
    refetch,
    venueOptionsData, 
    setOptionsRuleset,
    venueOptionsStatus,
    refetchVenueOptionsData,
    isVenueOptionsDataLoading,
  };
}
