import styles from "./CreateNewVenueModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { Modal } from "../../../shared/Modal";
import Select from "../Select";
import { RadioButtonList } from "Events/Components/RadioButtonList/RadioButtonList";
/**
 * An interactive component that displays the Create Venue Modal
 */

export function CreateNewVenueModal({
  isOpen,
  onCollapse,
  onClick,
  initialData,
  states,
}) {
  const [newName, setNewName] = useState("");
  const [newAddress1, setNewAddress1] = useState("");
  const [newAddress2, setNewAddress2] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newZip, setNewZip] = useState("");
  // const [newVenueURL, setNewVenueURL] = useState("");
  const [venuePhone, setVenuePhone] = useState("");
  const [venueFax, setVenueFax] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [venueURL, setVenueURL] = useState("");
  const [venueList, setVenueList] = useState([{ room: "", capacity: "" }]);
  const [contactList, setContactList] = useState([
    { contactName: "", phoneNumber: "", email: "", type: "" },
  ]);

  useEffect(() => {
    if (initialData) {
      setNewName(initialData.venueName || "");
      setNewAddress1(initialData.address1 || "");
      setNewAddress2(initialData.address2 || "");
      setNewCity(initialData.city || "");
      setNewState(initialData.state || "");
      setNewZip(initialData.zip || "");
      // setNewVenueURL(initialData.newVenueURL || "");
      setVenuePhone(initialData.venuePhone || "");
      setVenueFax(initialData.venueFax || "");
      setVenueEmail(initialData.venueEmail || "");
      setVenueURL(initialData.venueURL || "");
      setVenueList(initialData.venueList || [{ room: "", capacity: "" }]);
      setContactList(
        initialData.contactList || [
          { contactName: "", phoneNumber: "", email: "", type: "" },
        ]
      );
    }
  }, [initialData]);

  const onVenueRemove = (index) => {
    venueList.length > 1 &&
      setVenueList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    venueList.length === 1 && setVenueList(() => [{ room: "", capacity: "" }]);
  };

  const onVenueAdd = () => {
    setVenueList((arr) => [...arr, { name: "", capacity: "" }]);
  };

  const onContactRemove = (index) => {
    contactList.length > 1 &&
      setContactList((arr) => arr.filter((e) => arr.indexOf(e) !== index));
    contactList.length === 1 &&
      setContactList(() => [
        { contactName: "", phoneNumber: "", email: "", type: "" },
      ]);
  };

  const onContactAdd = () => {
    setContactList((arr) => [
      ...arr,
      { contactName: "", phoneNumber: "", email: "", type: "" },
    ]);
  };
  const [selectedCountryVenue, setSelectedCountryVenue] = useState("U.S.");
  const handleFormSubmittal = () => {
    const formData = {
      venueName: newName,
      address1: newAddress1,
      address2: newAddress2,
      city: newCity,
      state: newState,
      zip: newZip,
      // venueURL: newVenueURL,
      venuePhone: venuePhone,
      venueFax: venueFax,
      venueEmail: venueEmail,
      venueURL: venueURL,
      venueList: venueList,
      contactList: contactList,
    };
    onClick(formData);
  };

  const handleCountrySelectionVenue = (country) => {
    setSelectedCountryVenue(country);
    // ... other logic
  };

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Province*";
      case "JAMAICA":
        return "Parish*";
      default:
        return "State*";
    }
  };
  const getPostalLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Postal Code*";
      case "JAMAICA":
        return "Postal Code*";
      default:
        return "ZIP Code*";
    }
  };

  const handleStateSelection = (value) => {
    setNewState(value);
    // const extractState = value.split(" - ")[1];

    // const foundState = states?.find((state) => state.state === extractState);

    // if (foundState) {
    //   const stateId = String(foundState.stateId);

    //   handleInputChange("event", "state", stateId);
    //   handleBlur("event", "state", stateId);
    // }
  };

  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (states) {
      const matchingCountryIds = Object.keys(countryIdName).filter(
        (key) => countryIdName[key] === selectedCountryVenue
      );

      if (matchingCountryIds.length > 0) {
        const filteredStates = states
          .filter((state) =>
            matchingCountryIds.includes(state.countryId.toString())
          )
          .filter((state) => state.state !== "N/A")
          .map((state) => `${state.abbreviation} - ${state.state}`);

        //setStateOptions if they have changed,
        setStateOptions((prevStateOptions) => {
          const newOptions = filteredStates;
          return prevStateOptions.join() !== newOptions.join()
            ? newOptions
            : prevStateOptions;
        });
      } else {
        setStateOptions([]);
      }
    }
  }, [states, selectedCountryVenue]);

  const renderCountryField = () => {
    // Sequence of countries
    if (states) {
      const countrySequence = ["U.S.", "CDN", "ANZ", "JAMAICA"];
      const existingCountry = new Set(states.map((state) => state.countryId));

      // Filter valid country options
      const countryOptions = countrySequence.filter((countryName) =>
        Object.entries(countryIdName).some(
          ([id, name]) =>
            name === countryName && existingCountry.has(parseInt(id))
        )
      );

      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelectionVenue}
            options={countryOptions}
            defaultValue={selectedCountryVenue}
            groupName="venueCountry"
          />
        </span>
      );
    }
  };

  const renderStateSelection = () => {
    // const stateValue =
    //   eventData?.state?.value === 0 || eventData?.state === 0
    //     ? "N/A"
    //     : eventData?.state?.value || eventData?.state?.saved;

    // const hasStateError = validationErrors["state"];

    // Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = states?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    return (
      <div className={styles.stateContainer}>
        <div className={styles.stateLabel}>
          {getLabelForCountry(selectedCountryVenue) || "State*"}
        </div>
        <div className={styles.stateDropdown}>
          <Select
            placeholder={newState ? newState : "Select"}
            value={newState}
            options={stateOptions}
            onChange={handleStateSelection}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="CREATE VENUE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Name*"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 1*"
                  placeholder=""
                  value={newAddress1}
                  onChange={(e) => {
                    setNewAddress1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Address 2*"
                  placeholder=""
                  value={newAddress2}
                  onChange={(e) => {
                    setNewAddress2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country* </span>
                {renderCountryField()}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City*"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>{renderStateSelection()}</div>
              <div className={styles.inputRow}>
                <InputItem
                  label={
                    getPostalLabelForCountry(selectedCountryVenue) || "Zip*"
                  }
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Phone"
                  placeholder=""
                  value={venuePhone}
                  onChange={(e) => {
                    setVenuePhone(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Fax"
                  placeholder=""
                  value={venueFax}
                  onChange={(e) => {
                    setVenueFax(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue Email"
                  placeholder=""
                  value={venueEmail}
                  onChange={(e) => {
                    setVenueEmail(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Venue URL"
                  placeholder=""
                  value={venueURL}
                  onChange={(e) => {
                    setVenueURL(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              {venueList.map((room, index) => (
                <div className={styles.inputRow} key={index}>
                  <InputItem
                    label="Room"
                    value={room.room}
                    placeholder=""
                    onChange={(e) => {
                      room.room = e.target.value;
                      setVenueList([...venueList]);
                    }}
                  />
                  <InputItem
                    label="Capacity"
                    value={room.capacity}
                    placeholder=""
                    onChange={(e) => {
                      room.capacity = e.target.value;
                      setVenueList([...venueList]);
                    }}
                  />
                  <div className={styles.removeBtn}>
                    <Button
                      className={styles.remove}
                      children="Remove"
                      onClick={() => onVenueRemove(index)}
                    />
                  </div>
                </div>
              ))}
              <div className={styles.button}>
                <AddButton
                  color="#9AB5BB"
                  description="Add Another Room"
                  onClick={onVenueAdd}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <Button
                    className={styles.save}
                    children="SAVE VENUE"
                    onClick={() => handleFormSubmittal()}
                  />
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

CreateNewVenueModal.propTypes = {
  // Getter to determine if modal is displayed
  isOpen: PropTypes.bool,

  // Callback function for when the modal is closed
  onCollapse: PropTypes.func,

  // Function called when the save venue btn is clicked
  onClick: PropTypes.func,
};
